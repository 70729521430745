<template>
    <div>
        <div class="man-title">
            <span>超级管理员</span>
            <el-button @click="add" class="btn" type="primary" size="mini">新增管理员</el-button>
        </div>
        <div class="list-box">
            <el-table :data="tableData">
                <el-table-column label="序号" type="index" width="50"></el-table-column>
                <el-table-column prop="name" label="姓名" width="200"></el-table-column>
                <el-table-column prop="phone" label="电话" width="200"></el-table-column>
                <el-table-column prop="companyName" label="公司名称"></el-table-column>
                <el-table-column label="操作" width="180">
                    <template slot-scope="scope">
                        <el-dropdown placement="bottom-start" trigger="click">
                        <span @click.stop="">
                            <span class="el-dropdown-link">
                            操作<i class="el-icon-arrow-down"></i>
                            </span>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item><div @click="detail(scope.row)">详情</div></el-dropdown-item>
                            <el-dropdown-item><div @click="edit(scope.row)">编辑</div></el-dropdown-item>
                            <el-dropdown-item><div @click="dlt(scope.row)">删除</div></el-dropdown-item>
                        </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: []
        }
    },
    methods: {
        // queryAdmin
        add() {
            this.$router.push({name: 'adminisAdd'})
        },
        detail(row) {
            this.$router.push({ name: 'adminisDetail', params: { row: row } })
        },
        edit(row) {
            this.$router.push({ name: 'adminisEdit', params: row })
        },
        dlt(row) {
            this.$confirm("确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$ajax
                .post("staffDelete", {
                    id: row.id,
                })
                .then((res) => {
                    this.loadCompany();
                });
            });
        },
        loadCompany() {
            this.$ajax.get('queryAdmin').then(
                res => {
                    this.tableData = res.data
                }
            )
        }
    },
    mounted() {
        this.loadCompany()
    }
}
</script>

<style lang="less" scoped>
.form-box {
    width: 400px;
}
.btn {
    float: right;
  }
</style>
